body {
  background-color: #f8f8f8;
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus {
    background-color: #00d2a8;
}

.navbar .dropdown-menu {
    border-top: 1px solid #00d2a8;
}

.active-clients {
    background-color: #00d2a8;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: #00d2a8;
    border-color: #00d2a8;
}

.navbar,
.navbar-collapse {
    background-color: #00d2a8 !important;
}

.panel-heading {
    background-color: #286090 !important;
}


table.dataTable thead > tr > th,
table.invoice-table thead > tr > th {
    background-color: #777 !important;
    color:#fff;
}

thead th {
    border-left: 1px solid #999;
}

.sidebar-nav-dark {
    background-color: #313131;
}

.sidebar-nav-dark li {
    border-bottom:solid 1px #444444;
}

.sidebar-nav-dark li > a {
    color: #aaa;
}

.sidebar-nav-dark li:hover > a,
.sidebar-nav-dark li > a.active {
    color: #fff;
}

.sidebar-nav-dark li:hover,
.sidebar-nav-dark li.active {
    background: rgba(255,255,255,0.1);
}



.sidebar-nav-light {
    background-color: #FFFFFF;
}

.sidebar-nav-light li {
    border-bottom:solid 1px #DDD;
}

.sidebar-nav-light li > a {
    color: #757575;
}

.sidebar-nav-light li:hover > a,
.sidebar-nav-light li > a.active {
    color: #363636;
}

.sidebar-nav-light li:hover,
.sidebar-nav-light li.active {
    background: rgba(140,140,140,0.1);
}



.menu-toggle i {
    color: #fff;
}

.menu-toggle:hover {
    color: #fff;
}

.menu-toggle {
    color: #fff !important;
}

.sidebar-nav a.btn i.fa,
.navbar-header:hover i,
.menu-toggle:hover {
    color: #fff !important;
}
