html[direction='ltr'] div.dataTables_length label {
	float: left;
	text-align: left;
}
html[direction='rtl'] div.dataTables_length label {
	float: right;
	text-align: right;
}

div.dataTables_length select {
	width: 75px;
}

html[direction='ltr'] div.dataTables_filter label {
	float: right;
}
html[direction='rtl'] div.dataTables_filter label {
	float: left;
}

div.dataTables_info {
	padding-top: 26px;
}

div.dataTables_paginate {
	margin: 0;
}
html[direction='ltr'] div.dataTables_paginate {
	float: right;
}
html[direction='rtl'] div.dataTables_paginate {
	float: left;
}

table.table {
	clear: both;
	margin-bottom: 6px !important;
	max-width: none !important;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
	cursor: pointer;
	*cursor: hand;
}

/*
 * Use Glyphicons Halflings from Bootstrap 3 instead of images.
 *
 * Relevant icons:
 *
 * Glyphicons Halflings (default)
 *   glyphicon-sort			'\e150'		sort
 *   glyphicon-sort-by-attributes	'\e155'		asc
 *   glyphicon-sort-by-attributes-alt	'\e156'		desc
 *
 * Font Awesome
 *   fa-sort				'\f0dc'		sort
 *   fa-caret-up			'\f0d8'		asc
 *   fa-caret-down			'\f0d7'		desc
 */
table.table thead .sorting:after,
table.table thead .sorting_asc:after,
table.table thead .sorting_desc:after,
table.table thead .sorting_asc_disabled:after,
table.table thead .sorting_desc_disabled:after {
	font-family: 'Glyphicons Halflings';
}
html[direction='ltr'] table.table thead .sorting:after,
html[direction='ltr'] table.table thead .sorting_asc:after,
html[direction='ltr'] table.table thead .sorting_desc:after,
html[direction='ltr'] table.table thead .sorting_asc_disabled:after,
html[direction='ltr'] table.table thead .sorting_desc_disabled:after {
	text-align: right;
	float: right;
}
html[direction='rtl'] table.table thead .sorting:after,
html[direction='rtl'] table.table thead .sorting_asc:after,
html[direction='rtl'] table.table thead .sorting_desc:after,
html[direction='rtl'] table.table thead .sorting_asc_disabled:after,
html[direction='rtl'] table.table thead .sorting_desc_disabled:after {
	text-align: left;
	float: left;
}
table.table thead .sorting:after { content: '\e150'; opacity: 0.2; }
table.table thead .sorting_asc:after { content: '\e155'; }
table.table thead .sorting_desc:after { content: '\e156'; }
table.table thead .sorting_asc_disabled:after { content: '\e155'; opacity: 0.2; }
table.table thead .sorting_desc_disabled:after { content: '\e156'; opacity: 0.2; }

table.dataTable th:active {
	outline: none;
}

/* Scrolling */
div.dataTables_scrollHead table {
	margin-bottom: 0 !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
	border-top: none;
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
	border-top: none;
}

div.dataTables_scrollFoot table {
	border-top: none;
}




/*
 * TableTools styles
 */
.table tbody tr.active td,
.table tbody tr.active th {
	background-color: #08C;
	color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
	background-color: #0075b0 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
	background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
	cursor: pointer;
	*cursor: hand;
}

div.DTTT .btn {
	color: #333 !important;
	font-size: 12px;
}

div.DTTT .btn:hover {
	text-decoration: none !important;
}


ul.DTTT_dropdown.dropdown-menu a {
	color: #333 !important; /* needed only when demo_page.css is included */
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
	background-color: #0088cc;
	color: white !important;
}

/* TableTools information display */
div.DTTT_print_info.modal {
	height: 150px;
	margin-top: -75px;
	text-align: center;
}

div.DTTT_print_info h6 {
	font-weight: normal;
	font-size: 28px;
	line-height: 28px;
	margin: 1em;
}

div.DTTT_print_info p {
	font-size: 14px;
	line-height: 20px;
}



/*
 * FixedColumns styles
 */
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
table.DTFC_Cloned tr.even {
	background-color: white;
}

div.DTFC_LeftHeadWrapper table {
	margin-bottom: 0 !important;
	border-top-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

div.DTFC_LeftBodyWrapper table {
	border-top: none;
	margin-bottom: 0 !important;
}

div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
	border-top: none;
}

div.DTFC_LeftFootWrapper table {
	border-top: none;
}